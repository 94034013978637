.portfolio {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.portfolio__top {
  margin: 15px 15px 15px 15px;
  border-radius: 15px;
  background-color: #eff2f5;
  padding: 30px 150px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.portfolio__center {
  margin: 15px 15px 15px 15px;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 10px;
  height: 0;
  border-radius: 15px;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  min-height: 100vh;
}

.portfolio__item {
  margin: 15px 15px 15px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portfolio__item__desc {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
