.about {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.about__top {
  margin: 15px;
  border-radius: 15px;
  background-color: #eff2f5;
  padding: 30px 150px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.about__center {
  margin: 0.9em;
  border-radius: 0.9em;
  background-color: white;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: top;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  min-height: 100vh;
  max-width: 50vw;
}

.about__center__heading {
  display: flex;
  align-self: center;
}

.about__avatar {
  display: flex;
  align-self: center;
  margin-bottom: 20px;
}
