.login {
  flex: 1;
  display: flex;
  height: 100vh;

  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.login > button {
  margin-top: 6.25em;
  width: 18.75em;
  background-color: #171790;
  color: #eff2f5;
  font-weight: 800;
}

.login > button:hover {
  background-color: white;
  color: #171790;
}

.login > div {
  display: flex;
  flex-direction: column;
}

.login > div > img {
  margin-top: 3.125em;
  flex-direction: column;
  object-fit: contain;
  height: 9.375em;
}
