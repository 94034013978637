.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.contact__top {
  margin: 15px 15px 15px 15px;
  border-radius: 15px;
  background-color: #eff2f5;
  padding: 30px 150px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.contact__center {
  margin-bottom: 15px;
  border-radius: 15px;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
  min-height: 100vh;
}

.contact__intro {
  margin: 15px;
}

.contact__form {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}

.contact__form > h4 {
  margin: 15px;
}

label {
  justify-content: center;
  font-weight: bold;
  display: flex;
  margin-bottom: 15px;
}

input[type='text'],
input[type='email'],
textarea {
  display: block;
  width: 400px;
  padding: 6px, 12px;
  font-size: 14px;
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 15px;
  background-color: #eff2f5;
  margin-bottom: 20px;
}

.form__button {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 760px) {
  input[type='text'],
  input[type='email'],
  textarea {
    width: 200px;
  }
}
