.feed {
  padding: 5px;
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.sidebar__left {
  flex: 1;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
  box-shadow: 2px -2px 8px -6px rgba(0, 0, 0, 0.75);
}

.post__feed {
  flex: 2;
  padding: 10px;
}

.sidebar__right {
  flex: 1;
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 2px -2px 8px -6px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 760px) {
  .sidebar__left {
    display: none;
  }
  .sidebar__right {
    display: none;
  }
}
