.header {
  padding: 1.2em 20px;
  height: 2em;
  display: flex;
  justify-content: space-between;
  position: sticky;
  background-color: white;
  z-index: 100;
  top: 0;
  box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
}

.header__center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.header__option {
  display: flex;
  align-items: center;
  padding: 0 2em;
  cursor: pointer;
}

.header__option:hover {
  background-color: #eff2f5;
  border-radius: 10px;
  align-items: center;
  border-bottom: none;
}

.header__option > .MuiSvgIcon-root {
  color: gray;
}

.header__option:hover > .MuiSvgIcon-root {
  color: #191970;
}

.header__option--active > .MuiSvgIcon-root {
  color: #191970;
}

.header__option--active {
  border-bottom: 4px solid #191970;
}

.header__info {
  display: flex;
  align-items: center;
}

.header__right {
  display: flex;

  height: 2.2em;
}

.header__option--active > .MuiSvgIcon-root {
  color: #191970;
}

@media screen and (max-width: 760px) {
  .header__option {
    padding: 0 0.75em;
  }
}
