.footer {
  padding: 0.93em 1.25em;
  height: 1.875em;
  display: flex;
  justify-content: space-between;
  position: sticky;
  background-color: white;
  align-items: center;
  bottom: 0px;
  box-shadow: 0px -5px 9px -9px rgba(0, 0, 0, 0.75);
}

.footer__left {
  display: flex;
  font-size: xx-small;
  justify-content: space-evenly;
  color: gray;
  align-items: center;
}

.footer__copyright__text {
  display: flex;
  margin-top: 4px;
  font-size: small;
  padding: 0 5px 5px;
}

.footer__left > .MuiSvgIcon-root {
  color: gray;
  font-size: small;
}

.footer__center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.footer__option {
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
}

.footer__option:hover {
  background-color: #eff2f5;
  border-radius: 10px;
  align-items: center;
  padding: 0 30px;
  border-bottom: none;
}

.footer__option > .MuiSvgIcon-root {
  color: gray;
}

.footer__option:hover > .MuiSvgIcon-root {
  color: #191970;
}

.footer__right {
  display: flex;
  margin-left: 10vw;
}

@media screen and (max-width: 760px) {
  .footer__left {
    display: none;
  }
  .footer__option {
    padding: 0 0.75em;
  }
  .footer__copyright__text {
    display: none;
  }

  .footer__right {
    display: none;
  }
}
