.commentSender {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.commentSender__top {
  border-top: 1px solid lightgray;
  display: flex;
  border-bottom: 1px solid #eff2f5;
  padding-bottom: 15px;
  padding: 15px;
}

.commentSender__top > form {
  flex: 1;
  display: flex;
}

.commentSender__top > form > input {
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 999px;
  background-color: #eff2f5;
}

.commentSender__top > form > button {
  display: none;
}

.commentSender__input {
  flex: 1;
}

.commentSender__bottom {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 760px) {
  .commentSender__input {
    width: 2.8em;
  }
  .commentSender__top > form > button {
    display: flex;
    border-radius: 50%;
  }
}
