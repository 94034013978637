.post {
  border-radius: 15px;
  margin: 15px;
  background-color: white;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.post__top {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 15px 15px 0 0;
  background-color: #eff2f5;
  padding: 15px;
}

.post__topInfo > p {
  font-size: small;
  color: gray;
}

.post__topInfo__right {
  position: absolute;
  right: 0;
  margin-right: 15px;
  justify-content: flex-end;
}

.post__image {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

.post__media {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.post__bottom {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 20px;
}

.post__options {
  padding-top: 10px;
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: space-evenly;
  font-size: medium;
  color: gray;
  cursor: pointer;
  padding: 15px;
}

.post__option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex: 1;
}

.post__option > p {
  margin-left: 10px;
}

.post__option > .MuiSvgIcon-root {
  color: gray;
}

.post__option:hover {
  background-color: #eff2f5;
  border-radius: 10px;
}
.post__option:hover > .MuiSvgIcon-root {
  color: #191970;
}

.post__avatar {
  margin-right: 10px;
}

.post__topInfo > h3 {
  font-size: medium;
}

.post__topInfo > {
  font-size: small;
  color: gray;
}

.post__comment__count {
  font-size: small;
  color: gray;
  padding: 5px;
}
