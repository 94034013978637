.messageSender {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.messageSender__top {
  display: flex;
  border-bottom: 1px solid #eff2f5;
  padding-bottom: 15px;
  padding: 15px;
}

.messageSender__top > form {
  flex: 1;
  display: flex;
}

.messageSender__top > form > input {
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 999px;
  background-color: #eff2f5;
}

.messageSender__top > form > button {
  display: none;
}

.messageSender__input {
  flex: 1;
}

@media screen and (max-width: 760px) {
  .messageSender__input {
    width: 2.8em;
  }
  .messageSender__top > form > button {
    display: flex;
    border-radius: 50%;
  }
  .messageSender__top > form > input {
    margin: 3px;
  }
}
