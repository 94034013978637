.comment {
  display: flex;
  flex-direction: column;
}
.comment__body {
  display: flex;
  padding: 15px;
}

.comment__message {
  position: relative;
  font-size: 16px;
  padding: 15px;
  border-radius: 10px;
  width: 'fit-content';
  min-width: 90px;
  background-color: #eff2f5;
  margin-bottom: 10px;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.comment__timestamp {
  display: flex;
  position: absolute;
  bottom: -15px;
  right: 5px;
  font-size: xx-small;
}

.comment__avatar {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.comment__name {
  position: absolute;
  left: 5px;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}

.comment__delete {
  justify-content: center;
}
